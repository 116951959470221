import React from "react";
import "./AsharaSeason.css";
import GamesPreview from "./GamesPreview/GamesPreview";
import GamesInSeason from "../../data/GamesInSeason.json";
import BarProgress from "../../components/BarProgress/BarProgress";
import RedesSociales from "../../components/RedesSociales/RedesSociales";

export default function AsharaSeason() {
  let gamesInSeasonArray = GamesInSeason.games;
  let playersArray = [
    { sand: 520, players: 10 },
    { sand: 520, players: 20 },
    { sand: 520, players: 30 },
    { sand: 520, players: 40 },
    { sand: 520, players: 50 },
    { sand: 520, players: 60 },
    { sand: 520, players: 70 },
    { sand: 520, players: 80 },
    { sand: 520, players: 90 },
    { sand: 520, players: 100 },
  ];

  return (
    <article>
      <section>
        <h1 className="title-season">Ashara Season</h1>
        <hr />
        <p className="text-season">
          The Ashara Season is a ten weeks event that kicks off on{" "}
          <b>June 24th </b>
          and concludes on <b>September 2nd</b>.
          <br />
          During this period, players will engage in missions across various
          games developed by <b>Ashara Studios</b>.
          <br />
          The rewards players can receive range from <b>SAND</b> to <b>NFTs</b>,
          depending on their participation in the event.
          <br />
          Get ready to dive into this exciting season of challenges and rewards!
        </p>
      </section>
      <section className="container-gameSeason">
        {gamesInSeasonArray.map((game, index) => (
          <GamesPreview
            key={"article" + index}
            gameName={game.GameName}
            coverImage={game.CoverImage1}
            link={game.Link}
          />
        ))}
      </section>
      <hr />

      <section className="container-sandPool">
        <h1 className="title-season">Sand reward pool</h1>
        <hr />

        <div className="container-barProgress">
          <p className="info-pool">
            1000
            <br /> Sand Reward
            <br /> pool
          </p>
          <img
            className="initial-bar"
            src="/img/AsharaSeason/Bar/line0.png"
            alt="Inital bar"
          />
          <img
            className="backgorund-barProgress"
            src="/img/AsharaSeason/Bar/BG.png"
            alt="backgorund"
          />
          <div className="container-barProgress2">
            {playersArray.map((milestone, index) => (
              <BarProgress
                key={"Hito" + index}
                sand={milestone.sand}
                milestone={milestone.players}
              />
            ))}
          </div>

          <div className="container-reward">
            <img
              className="backgorund-reward"
              src="/img/AsharaSeason/Bar/coin2BG.png"
              alt="SAND 2 BG"
            />
            <img
              className="coin-reward"
              src="/img/AsharaSeason/Bar/coin2.png"
              alt="SAND 2"
            />
            <p>
              <span className="italic">TOTAL SAND!</span>
              <br /> 5000**
            </p>
          </div>
        </div>
        <hr />

        <div className="container-infoReward">
          <img
            className="backgorund-infoReward"
            src="/img/AsharaSeason/FrameInfo.png"
            alt="backgorund"
          />
          <p>
            **For every bi-weekly period we achieve top 1-3 experiences, each
            milestone reward will increase by 30%, up to{" "}
            <span>16,000 SAND</span> in prizes!
          </p>
          <img
            className="chest-infoReward"
            src="/img/AsharaSeason/chest.png"
            alt="backgorund"
          />
        </div>
        <h2 className="txt-invite">Invite Your Friends to unlock more sand!</h2>
      </section>
      <hr />
      <div className="container-info-benefits">
        <img
          className="backgorund-info-benefits"
          src="/img/AsharaSeason/ImageAsharaPass.png"
          alt="backgorund"
        />
      </div>
      <div className="container-info">
        <section className="container-infoSeason">
          <div className="container-benefits">
            <h2 className="subtitle-season">BENEFITS</h2>
            <ul>
              <li>Weekly Commemorative Medal.</li>
              <hr />
              <li>Share the SAND reward pool.</li>
              <hr />
              <li>Multiplier in the final score for the reward.</li>
              <hr />
              <li>Monthly cosmetic NFT reward.</li>
              <hr />
              <li>Monthly Armor part with uncommon catalyst.</li>
              <hr />
              <li>The Season top 3 will receive a full armor set.</li>
              <hr />
            </ul>
          </div>
          <div className="element-free">
            <div className="content-free">
              <h2 className="subtitle-season">FREE TO PLAY</h2>
              <ul>
                <li>
                  If you complete 60% of quest.
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/blue.png"
                    alt="blue"
                  />
                  <hr />
                </li>
                <li>
                  If you complete the 70% of season quests.
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/blue.png"
                    alt="blue"
                  />
                  <hr />
                </li>
                <li>
                  x1
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/blue.png"
                    alt="blue"
                  />
                  <hr />
                </li>
                <li>
                  If you complete 100% of quest.
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/blue.png"
                    alt="blue"
                  />
                  <hr />
                </li>
                <li>
                  No
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/blue.png"
                    alt="blue"
                  />
                  <hr />
                </li>
                <li>
                  No
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/blue.png"
                    alt="blue"
                  />
                  <hr />
                </li>
              </ul>
            </div>
          </div>
          <div className="element-pass">
            <div className="content-pass">
              <div className="subtitle-season">
                <img
                  className="subtitle-start"
                  src="/img/AsharaSeason/start.png"
                  alt="purple"
                />
                <img
                  className="subtitle-start"
                  src="/img/AsharaSeason/start2.png"
                  alt="purple"
                />
                <h2> ASHARA PASS</h2>
                <img
                  className="subtitle-start"
                  src="/img/AsharaSeason/start2.png"
                  alt="purple"
                />
                <img
                  className="subtitle-start"
                  src="/img/AsharaSeason/start.png"
                  alt="purple"
                />
              </div>

              <ul>
                <li>
                  Yes
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/purple.png"
                    alt="purple"
                  />
                  <hr />
                </li>
                <li>
                  If you complete the 60% of season quests.
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/purple.png"
                    alt="purple"
                  />
                  <hr />
                </li>
                <li>
                  x2
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/purple.png"
                    alt="purple"
                  />
                  <hr />
                </li>
                <li>
                  If you complete 100% of quest.
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/purple.png"
                    alt="purple"
                  />
                  <hr />
                </li>
                <li>
                  If you complete 100% of quest.
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/purple.png"
                    alt="purple"
                  />
                  <hr />
                </li>
                <li>
                  Yes
                  <img
                    className="shadow"
                    src="/img/AsharaSeason/purple.png"
                    alt="purple"
                  />
                  <hr />
                </li>
              </ul>
            </div>
            <a
              href="https://opensea.io/assets/matic/0xf4d87dff7671c8770767a22c5f9ef29f2a81dd48/0"
              target="_blank"
              rel="noreferrer"
              className="button2 btn-info"
            >
              Buy here!
            </a>
          </div>
        </section>
        <img
          className="float-turtle"
          src="/img/AsharaSeason/ImagePass.png"
          alt="Tutle"
        />
      </div>
      <section className="container-socialNetwork">
        <h1 className="socialNetwork-followUs">Follow Us</h1>
        <RedesSociales />
      </section>
    </article>
  );
}
